import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import { Link } from "gatsby"
import privacyPolicy from "../assets/images/privacy-policy.jpg"
import { Helmet } from "react-helmet"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mexxar | Privacy Policy</title>
        <link rel="mexxar" href="http://mexxar.com" />
        <meta
          name="description"
          content="Mexxar is a robust consultancy and professional software development and IT services provider. At Mexxar, we specialize in developing revolutionized and innovative software-based products for different companies and applications in diversified industries."
        />
        <meta name="author" content="Mexxar(Pvt)Ltd" />
        <meta
          name="keywords"
          content="CCMS, Danu, Narada, Software, Company, Sri, Lanka, Outsource, Healthcare, Medical, Solutions, privacy, policy, mexxar, ccms, software, development"
        />
        <meta property="og:title" content="Mexxar" />
        <meta property="og:image" content="your_image_url" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" />
        <meta
          name="twitter:title"
          content="Parade of Fans for Houston’s Funeral"
        />
        <meta
          name="twitter:description"
          content="NEWARK - The guest list and parade of limousines with celebrities emerging from them seemed more suited to a red carpet event in Hollywood or New York than than a gritty stretch of Sussex Avenue near the former site of the James M. Baxter Terrace public housing project here."
        />
        <meta
          name="twitter:image"
          content="http://graphics8.nytimes.com/images/2012/02/19/us/19whitney-span/19whitney-span-articleLarge.jpg"
        />
      </Helmet>
      <Navbar />
      <PageBanner
        pageTitle="Privacy Policy"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Privacy Policy"
      />
      <section className="privacy-policy-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="privacy-policy-content">
                <p>
                  <i>
                    This Privacy Policy was last updated on January 1, 2020.
                  </i>
                  <div>
                  
  
      <div>
         <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}>LEGAL</span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%', background: 'yellow'}}>Effective Date- 01<sup>st</sup> of April 2023</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Mexxar is operated by Mexxar (Pvt.) Ltd, a company registered in Sri Lanka with registered office at 3D Sangamitta Mawatha, Kandy, Sri Lanka.&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Mexxar (PVT) LTD collects information about you when you use our Mexxar Use app, websites and other online products and services and through other interactions and communications you have with us. The privacy statement applies to information collected and used by Mexxar(PVT) LTD.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We recommend that you read this policy carefully and in its entirely in order understand what Mexxar (herein after referred to as “we”, “us”, or “Mexxar”) do with your personal information. Your use of our services and applications and any dispute over privacy is subject to this policy and our applicable terms and conditions for use of our service. By using our services, you are accepting and consenting to the practices described in this policy.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Scope and Application</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>The privacy policy statement applies to all persons who use the Mexxar app, website or any other online products or services (collectively, “the services) provided by Mexxar. &nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>When you use our services, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you with a safe, efficient, smooth, and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our Website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We may change this Statement from time to time. If we make significant changes in the way we treat your personal information, or to the Statement, we will provide you notice through the Services or by some other means, such as email. However, it shall be your duty to periodically review the Statement for the latest information on our privacy practices. Your continued use of the Services after such notice constitutes your consent to the changes.&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}>COLLECTION OF INFORMATION</span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Direct collection of information&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We collect information you provide directly to us, such as when you create or modify your account, request on demand services, contact customer support, or otherwise communicate with us. This information may include:&nbsp;</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Details about you including;</span>
            <ol style={{listStyleType: 'circle'}}>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Your Name&nbsp;</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>The name of the agency you represent</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Age</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Date of Birth</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Marital status</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Contact Numbers</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Address</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Education Details</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Employment history</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Emergency contacts and details of any dependents</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Referee details</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Photograph</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Immigration status</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Nationality/Citizenship/Place of Birth</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>A copy of your passport/ driving license/ Identity card/ Evidence of name changes</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Diversity information including racial and ethnic origin, religious or other similar beliefs, and physical or mental health, including disability related information</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Details of any criminal convictions for role that require this information</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Details about your current remuneration, pensions and benefits arrangements</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Information on your interests and needs regarding current and future employment, collected directly and inferred, for examples from jobs viewed or articles read on our website</span></li>
            </ol>
          </li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Financial Information to the extent that may be required to transfer funds and/or deposits to you and your preferred payment methods</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Social Security number</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Any extra information that you choose to tell us&nbsp;</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Any extra information collected from your referees about you&nbsp;</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Any extra information that your clients may tell us about you</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Any extra information that we find from other third-party sources such as job sites</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>IP addresses</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Details about your location</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Details of notifications that are automatically sent to you</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>The frequency, time and date at which you access our services</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Data obtained from time sheets including the your handwriting and writing patterns</span></li>
        </ul>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Information we collect from other sources</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We may also receive information from other sources and combine that with information we collect through our services. For example :</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>If you decide to enter in to your Mexxar account through social media (Eg-Facebook), Display ad or if you engage with a separate app that uses our API (or whose API we use), we may receive information about you and your connections from that site or app</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>If an agency with which you have registered with use our enterprise solutions, we may receive information about you from the agency</span></li>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>If you also interact with our services in another capacity, for instance, as an agent or user of other apps we provide we may combine or associate that information with information we have collected from you in your capacity as a User or mobility service provider.</span></li>
        </ul>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Information collected through Tracking Technologies and Cookies</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We use Cookies and similar tracking technologies to track the activity on our service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our service. The technologies we use may include:</span></p>
        <ul style={{marginBottom: '0cm', marginTop: '0cm'}} type="disc">
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Cookies or Browser Cookies.</span></strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;A cookie is a small file placed on your Device. You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, You may not be able to use some parts of our service. Unless you have adjusted your browser setting so that it will refuse Cookies, our service may use Cookies.</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Flash Cookies.</span></strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;Certain features of our service may use local stored objects (or Flash Cookies) to collect and store information about your preferences or your activity on our service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies.&nbsp;</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Web Beacons.</span></strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;Certain sections of our service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</span></li>
        </ul>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Important information about platform permissions&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Most mobile platforms (ios, Andriod etc) have defined certain types of device data that apps cannot access without your consent. And these platforms have different permission systems for obtaining your consent. The ios platform will alert you the first time Mexxar app wants permission to access certain types of data and will let you consent (or not consent) to the request. Android devices will notify you of the permission that the Mexxar app seeks before you first use the app, and your use of the app constitutes your consent. Sometimes these permissions require more explanations than the platforms themselves provide, and the permissions we request will change over time.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}>USE OF INFORMATION</span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We may use information we collect about you to :</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Provide, maintain, and improve our services, including for example, to provide services you request, develop new features, provide customer support to clients and work seekers, develop safety features, authenticate users and product updates and administrative messages. In particular, we use your handwriting data to improve our machine learning algorithms to provide more accurate recognition and a more effective automation process.&nbsp;</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '36.0pt', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Perform internal operations, including, for example, to prevent fraud and abuse of our services; to troubleshoot software bugs and operational problems; to conduct data analysis, testing and research; and to monitor and analyse usage and activity trends.</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '36.0pt', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '16px'}}>&nbsp;</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Send or facilitate communications;</span>
            <ol style={{listStyleType: 'circle'}}>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>between the client and work seeker such as time of arrival to the hospital and/or&nbsp;</span></li>
              <li><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '12.0pt'}}>Send you communications we think will be of interest to you, including information about products, services, promotions, news, and events of Mexxar and other companies, where permissible and according to applicable laws; and to process contest, sweepstake, or other promotion entries and fulfil any related awards;</span></li>
            </ol>
          </li>
        </ul>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services you have purchased or of any other contract with us through the service</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>Personalize and improve the Services, including to provide or recommend features, content, social connections, referrals, and advertisements. We may transfer the information described in this Statement to, and process and store it in UK and other countries, some of which may have less protective data protection laws than the region in which you reside. Where this is the case, we will take reasonable measures to protect your personal information in accordance with this Statement.</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}>STORAGE, RETENTION AND HANDLING OF DATA</span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Where We Store Your Data</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}><br />&nbsp;</span></strong><span style={{fontSize: '16px', lineHeight: '150%'}}>All information you provide to us are stored on secure servers and data centers within the United Kingdom. We take reasonable administrative, physical and electronic measures designed to safeguard and protect your information from unauthorized access or disclosure.&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Retention of Your Personal Data</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Mexxar will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Mexxar will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our service, or we are legally obligated to retain this data for longer time periods.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Transfer of Your Personal Data</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Your information, including Personal Data, is processed at the Mexxar's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer. Mexxar will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}>DISCLOSURE OF YOUR PERSONAL DATA</span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Business Transactions</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>If the Company is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Law enforcement</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Under certain circumstances, Mexxar may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Other legal requirements</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Mexxar may disclose your Personal Data in the good faith belief that such action is necessary to:</span></p>
        <ul style={{marginBottom: '0cm', marginTop: '0cm'}} type="disc">
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Comply with a legal obligation</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Protect and defend the rights or property of the Company</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Prevent or investigate possible wrongdoing in connection with the service</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Protect the personal safety of users of the service or the public</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Protect against legal liability</span></li>
        </ul>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}>SECURITY OF YOUR PERSONAL DATA</span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>The security of your personal data is important to us, but note that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}>COMMITMENT TO GDPR</span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>The General Data Protection Regulation (GDPR) became law in the EU in May 2018, replacing the 1995 EU Data Protection Directive (European Directive 95/46/EC). &nbsp;The GDPR outlines rights of all EU citizens to control their data and creates a uniform data protection law across Europe. &nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Mexxar is committed to protection to the protection of our customer data and is compliant with all applicable GDPR regulations with data collected across all products and services offered. Evaluations are carried out to stay updated on and identify new data protection laws and regulations in the countries, states and provinces in which the company operates</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Data Subject Rights</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>The GDPR provides individuals the below subject rights:</span></p>
        <ul style={{marginBottom: '0cm', marginTop: '0cm'}} type="disc">
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Right to be informed of what data of theirs is being collected, how it’s being used, how long it will be kept and whether it will be shared with any third parties.</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Right of access and request a copy of the information that an organization holds on them.</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Right of rectification to correct data that is inaccurate or incomplete.</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Right to be forgotten: Individuals can request organizations to erase any personal data that is stored on them under certain circumstances. &nbsp;&nbsp;</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Right to object or challenge certain types of processing, including direct marketing.</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Rights related to automated decision making, including profiling: individuals can ask organization’s to provide a copy of its automated processing activities if they believe the data is being processed unlawfully.&nbsp;</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Right to restrict processing and limit the way an organization uses personal data</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Right of portability: Individuals can request that an organization transfers any data that it holds on them to another company.</span></li>
          <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></li>
        </ul>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>The Mexxar services are software products provided to companies to manage their recruitment process. If you are a registered customer/user with one of our clients and want to exercise your right as a data subject you will need to contact them directly.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Mexxar (Pvt.) Ltd does not have access to customer data and so will be unable to help with any requests in this regard. &nbsp;If you are unable to carry out the request by logging into the client’s careers site you could try looking at their privacy statement which will provide contact details to help you. Please note that it needs to be the privacy policy of the company you applied to and not Mexxar.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>If you still need assistance after contacting the company directly, please contact us at the contact point below and we will do our best to help.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}>SHARING OF INFORMATION</span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We may share the information we collect about you as described in this Statement or as described at the time of collection or sharing, including as follows:</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>THROUGH OUR SERVICES</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We may share your information:</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>With work seekers to enable them to provide the Services you need. For example, we share your name, photo (if you provide one), average User rating given by Clients as directed by you, such as when you want to share your qualifications and credentials;</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>With third parties to provide you a service you requested through a partnership or promotional offering made by a third party or us; With the general public if you submit content in a public forum, such as blog comments, social media posts, or other features of our Services that are viewable by the general public;</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '36.0pt', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '16px'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>With third parties with whom you choose to let us share information, for example other apps or websites that integrate with our API or Services, or those with an API or Service with which we integrate; and</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>With your employer (or similar entity) and any necessary third parties engaged by us or your employer (e.g., an expense management service provider), if you participate in any of our enterprise solutions such as Mexxar Services.</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>OTHER IMPORTANT SHARING</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We may share your information:</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>With Mexxar subsidiaries and affiliated entities that provide services or conduct data processing on our behalf, or for data centralization and / or logistics purposes;</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>With vendors, consultants, marketing partners, and other service providers who need access to such information to carry out work on our behalf;</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>In response to a request for information by a competent authority if we believe disclosure is in accordance with, or is otherwise required by, any applicable law, regulation, or legal process;</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>With law enforcement officials, government authorities, or other third parties if we believe your actions are inconsistent with our User agreements, Terms of Service, or policies, or to protect the rights, property, or safety of Mexxar or others;</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>In connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company;</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'disc'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif'}}><span style={{lineHeight: '150%', fontFamily: '"Calibri",sans-serif', fontSize: '16px'}}>If we otherwise notify you and your consent to the sharing; and in an aggregated and/or anonymized form which cannot reasonably be used to identify you.</span></li>
          </ul>
        </div>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>SOCIAL SHARING FEATURES</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Our Services may integrate with social sharing features and other related tools which let you share actions you take on our Services with other apps, sites, or media, and vice versa.&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the social sharing service. Please refer to the privacy policies of those social sharing services for more information about how they handle the data you provide to or share through them.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>SHARING WITH ANALYTICS AND ADVERTISING SERVICES PROVIDERS</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>We may allow others to provide audience measurement and analytics services for us, to serve</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>advertisements on our behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use cookies, web beacons, SDKs, and other technologies to identify your device when you visit our site and use our Services, as well as when you visit other online sites and services.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><u><span style={{fontSize: '16px', lineHeight: '150%'}}>YOUR CHOICES</span></u></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Account Information</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>You may correct your account information at any time by logging into your online or in-app account. If you wish to cancel your account, please email us at info@mexxar.com. Please note that in some cases we may retain certain information about you as required by law, or for legitimate business purposes to the extent permitted by law. For instance, if you have a standing credit or debt on your account, or if we believe you have committed fraud or violated our Terms; we may seek to resolve the issue before deleting your information.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Access Rights</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>Mexxar will comply with individual’s requests regarding access, correction, and/or deletion of the personal data it stores in accordance with applicable law.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Promotional Communications</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>You may opt out of receiving promotional messages from us by following the instructions in those</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>messages. If you opt out, we may still send you non-promotional communications, such as those about your account, about Services you have requested, or our ongoing business relations.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>Contact Us</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><strong><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></strong></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>If you have any questions about this Privacy Statement, please contact us at info@mexxar.com, or write us at Mexxar Solutions &nbsp;(Pvt) Ltd 3D Sangamitta Mawatha, Kandy, Sri Lanka.</span></p>
        <p style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', lineHeight: '150%'}}><span style={{fontSize: '16px', lineHeight: '150%'}}>&nbsp;</span></p>
      </div>
    
                  </div>
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <aside className="widget-area">
                <div className="widget widget_insight">
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="#">Purchase Guide</Link>
                    </li>
                    <li className="active">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="#">Terms of Service</Link>
                    </li>
                  </ul>
                </div>

                {/* <div className="widget widget_recent_courses">
                                    <h3 className="widget-title">Recent Courses</h3>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg1" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$49.00</span>
                                            <h4 className="title usmall"><Link to="#">The Data Science Course 2020: Complete Data Science Bootcamp</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$59.00</span>
                                            <h4 className="title usmall"><Link to="#">Java Programming MasterclassName for Software Developers</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$69.00</span>
                                            <h4 className="title usmall"><Link to="#">Deep Learning A-Z™: Hands-On Artificial Neural Networks</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>
                                </div>

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                        <Link to="#">Business <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Design <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Braike <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Fashion <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Travel <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Smart <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Marketing <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Tips <span className="tag-link-count"> (2)</span></Link>
                                    </div>
                                </div> */}
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy
